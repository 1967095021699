.foreground {
    height:100%;
    display: flex;
    justify-content: center;
}

.top{
    margin-top:40px;
    vertical-align:center;
    text-align:center;
    width:100%;
    height:200px;
    opacity:1;
    position:absolute;
    z-index:10;
    color:firebrick;
    font-weight: bold;
    right:0;
}

 .savingLives, .everyDay{
    color: #0b3f76; 
    font-family: "Arial";
    font-size: 4em;
    font-weight: 600;
    margin: 10px 0 0 20px;
    white-space: nowrap;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }

  .partner span{
    color:firebrick;
    text-align: center;
    width:100%;
    font-weight: 600;
    position: absolute;
    top:40%;
  }

  @media (max-width: 320px) {
    .top{
      padding:10px;
        margin-top:10px;
    }

    .savingLives, .everyDay{
      font-weight:790;
      font-size:1.3em;
      height:40px;
      position:absolute;
      position: absolute;
      text-align: center;
      width: 100%;
      left: 0;
    }

    .everyDay{
      top:25%;
    }

    .donation{
      text-align:center;
      background-color: rgba(245,245,245,0.7);
      width:100%;
      height:110px;
      opacity:0.85;
      position:absolute;
      top:20%;
      z-index:10;
      color:firebrick;
      font-weight: bold;
      padding-left:0;
      left: 0;
      margin-top:40px; 
  }

    .donation span{
      position:absolute;
      top:10%;
      font-size:1.3em;
      font-weight:800;
    }

    .btn{
      background-color: #0b3f76 !important;
      border: 0;
      height: 32px;
      width: 110px;
      font-weight: 600;
      font-size:0.9em;
      position:absolute;
      top:55%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    .emergency{
      padding:10px;
      position:absolute;
      z-index:10;
      background-color: firebrick;
      width:100%;
      /* padding-left:0; */
      top: 67%;
      left: 0;
      height:65px;
      /* margin-top:40px; */
    }
    
    .emergency span{
        display:inline-block;
        color:white;
        text-align:center;
        font-size:1.3em;
        font-weight:525;
        position:absolute;
        top:0;
        left:0;
        margin-top:1px;
    }

    .partner{
      display:flex;
      flex-direction: column;
      justify-content:flex-start;
      position:absolute;
      top:100%;
      background-color: rgba(245,245,245,0.7);
      opacity:0.9;
      width:100vw;
      margin-bottom:200px;
      height:370px;
    }

    .iconWrap{
      display: inline-block; 
      margin: auto;
      margin-top:25px;
      text-align:center;
  }
  
    .icon{
        border-radius:100px;
        margin-top:0;
        margin-bottom:0;
        background-color: #0b3f76;
        height: 4rem;
        width: 4rem;
        font-size: 2.5rem;
        color: white;
        font-weight: bolder;
        text-align:center;
    }

    .partner span{
      font-size: 2em;
      position: absolute;
      top:30%;
    }

    .partner p{
      margin-top:20px;
      text-align: center;
      /* font-family: 'Noto serif'; */
      padding:25px;
      font-size:1.1em;
      font-weight:500;
      position:absolute;
      top:35%;

    }

    .partner .btn{
      margin-top:10px;
      position:absolute;
      top:80%
    }
  }

  @media (min-width: 321px) and (max-width: 414px) {
    .top{
      padding:10px;
        margin-top:20px;
    }

    .savingLives, .everyDay{
      font-weight:790;
      font-size:1.8em;
      height:40px;
      position:absolute;
      position: absolute;
      text-align: center;
      width: 100%;
      left: 0;
    }

    .everyDay{
      top:25%;
    }

    .donation{
      text-align:center;
      background-color: rgba(245,245,245,0.7);
      width:100%;
      height:110px;
      opacity:0.85;
      position:absolute;
      top:20%;
      z-index:10;
      color:firebrick;
      font-weight: bold;
      padding-left:0;
      left: 0;
      margin-top:40px; 
  }

    .donation span{
      position:absolute;
      top:10%;
      font-size:1.8em;
      font-weight:800;
    }

    .btn{
      background-color: #0b3f76 !important;
      border: 0;
      height: 32px;
      width: 110px;
      font-weight: 600;
      font-size:0.9em;
      position:absolute;
      top:55%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    .emergency{
      padding:10px;
      position:absolute;
      z-index:10;
      background-color: firebrick;
      width:100%;
      /* padding-left:0; */
      top: 67%;
      left: 0;
      height:65px;
      /* margin-top:40px; */
    }
    
    .emergency span{
        display:inline-block;
        color:white;
        text-align:center;
        font-size:1.3em;
        font-weight:525;
        position:absolute;
        top:0;
        left:0;
        margin-top:1px;
    }

    .partner{
      display:flex;
      flex-direction: column;
      justify-content:flex-start;
      position:absolute;
      top:100%;
      background-color: rgba(245,245,245,0.7);
      opacity:0.9;
      width:100vw;
      margin-bottom:200px;
      height:370px;
    }

    .iconWrap{
      display: inline-block; 
      margin: auto;
      margin-top:25px;
      text-align:center;
  }
  
    .icon{
        border-radius:100px;
        margin-top:0;
        margin-bottom:0;
        background-color: #0b3f76;
        height: 4rem;
        width: 4rem;
        font-size: 2.5rem;
        color: white;
        font-weight: bolder;
        text-align:center;
    }

    .partner span{
      font-size: 2em;
      position: absolute;
      top:30%;
    }

    .partner p{
      margin-top:20px;
      text-align: center;
      /* font-family: 'Noto serif'; */
      padding:25px;
      font-size:1.1em;
      font-weight:500;
      position:absolute;
      top:35%;

    }

    .partner .btn{
      margin-top:10px;
      position:absolute;
      top:80%
    }
  }
    
  @media (min-width: 415px) and  (max-width: 767px){
    .top{
      padding:0px;
      margin-top:10px;
    }

    .savingLives, .everyDay{
      font-weight:790;
      font-size:2.5em;
      height:70px;
      position:absolute;
      position: absolute;
      text-align: center;
      width: 100%;
      left: 0;
      /* margin-bottom: 20px; */
    }

    .savingLives{
      top:10%
    }
    .everyDay{
      top:45%;
     }

    .donation{
      text-align:center;
      background-color: rgba(245,245,245,0.7);
      width:100%;
      height:135px;
      opacity:0.85;
      position:absolute;
      top:18%;
      z-index:10;
      color:firebrick;
      font-weight: bold;
      padding-left:0;
      left: 0;
      margin-top:40px; 
  }

    .donation span{
      position:absolute;
      top:6%;
      font-size:2.5em;
      font-weight:800;
    }

    .btn{
      background-color: #0b3f76 !important;
      border: 0;
      height: 40px;
      width: 160px;
      font-weight: 600;
      font-size:1.2em;
      position:absolute;
      top:55%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    .emergency{
      padding:10px;
      position:absolute;
      z-index:10;
      background-color: firebrick;
      width:100%;
      /* padding-left:0; */
      top: 60%;
      left: 0;
      height:35px;
      /* margin-top:40px; */
    }
    
    .emergency span{
        display:inline-block;
        color:white;
        text-align:center;
        font-size:1.3em;
        font-weight:525;
        position:absolute;
        top:0;
        left:0;
    }

    .partner{
      display:flex;
      flex-direction: column;
      justify-content:flex-start;
      position:absolute;
      top:100%;
      background-color: rgba(245,245,245,0.7);
      opacity:0.9;
      width:100vw;
      margin-bottom:200px;
      height:370px;
      align-items: center;
    }

    .iconWrap{
      display: inline-block; 
      margin: auto;
      margin-top:25px;
      text-align:center;
  }
  
    .icon{
        border-radius:100px;
        margin-top:0;
        margin-bottom:0;
        background-color: #0b3f76;
        height: 4.5rem;
        width: 4.5rem;
        font-size: 3em;
        color: white;
        font-weight: bolder;
        text-align:center;
    }

    .partner span{
      font-size: 2.2em;
      font-weight: 700;
      position: absolute;
      top:30%;
    }

    .partner p{
      margin-top:25px;
      text-align: center;
      /* font-family: 'Noto serif'; */
      padding:25px;
      font-size:1.2em;
      font-weight:600;
      position:absolute;
      top:35%;
      max-width:600px;
    }

    .partner .btn{
      margin-top:2px;
      position:absolute;
      top:78%;
      font-size:1.2em;
      height:45px;
      width:140px;
    }
  }

  @media (min-width: 768px) and  (max-width: 1023px){
    .top{
      padding:0px;
      margin-top:20px;
    }

    .savingLives, .everyDay{
      font-weight:790;
      font-size:3.5em;
      height:70px;
      position:absolute;
      position: absolute;
      text-align: center;
      width: 100%;
      left: 0;
      /* margin-bottom: 20px; */
    }

    .savingLives{
      top:10%
    }
    .everyDay{
      top:50%;
     }

    .donation{
      text-align:center;
      background-color: rgba(245,245,245,0.7);
      width:100%;
      height:180px;
      opacity:0.85;
      position:absolute;
      top:16%;
      z-index:10;
      color:firebrick;
      font-weight: bold;
      padding-left:0;
      left: 0;
      margin-top:40px; 
  }

    .donation span{
      position:absolute;
      top:1%;
      font-size:3.5em;
      font-weight:800;
    }

    .btn{
      background-color: #0b3f76 !important;
      border: 0;
      height: 50px;
      width: 180px;
      font-weight: 600;
      font-size:1.5em;
      position:absolute;
      top:55%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    .emergency{
      padding:10px;
      position:absolute;
      z-index:10;
      background-color: firebrick;
      width:100%;
      /* padding-left:0; */
      top: 53.5%;
      left: 0;
      height:40px;
      /* margin-top:40px; */
    }
    
    .emergency span{
        display:inline-block;
        color:white;
        text-align:center;
        font-size:1.5em;
        font-weight:525;
        position:absolute;
        top:0;
        left:0;
    }

    .partner{
      display:flex;
      flex-direction: column;
      justify-content:start;
      position:absolute;
      top:100%;
      background-color: rgba(245,245,245,0.7);
      opacity:0.9;
      width:100vw;
      margin-bottom:200px;
      height:370px;
      align-items: center;
    }

    .iconWrap{
      display: inline-block; 
      margin: auto;
      margin-top:25px;
      text-align:center;
  }
  
    .icon{
        border-radius:100px;
        margin-top:0;
        margin-bottom:0;
        background-color: #0b3f76;
        height: 4.5rem;
        width: 4.5rem;
        font-size: 3em;
        color: white;
        font-weight: bolder;
        text-align:center;
    }

    .partner span{
      font-size: 2.2em;
      font-weight: 700;
      position: absolute;
      top:30%;
    }

    .partner p{
      margin-top:25px;
      text-align: center;
      /* font-family: 'Noto serif'; */
      padding:25px;
      font-size:1.2em;
      font-weight:600;
      position:absolute;
      top:35%;
      max-width:600px;
    }

    .partner .btn{
      margin-top:2px;
      position:absolute;
      top:78%;
      font-size:1.2em;
      height:45px;
      width:140px;
    }
  }

  @media (min-width: 1024px) and  (max-width: 1599px){
    .top{
      padding:0px;
      margin-top:20px;
    }

    .savingLives, .everyDay{
      font-weight:790;
      font-size:3.5em;
      height:70px;
      position:absolute;
      position: absolute;
      text-align: center;
      width: 100%;
      left: 0;
      /* margin-bottom: 20px; */
    }

    .savingLives{
      top:10%
    }
    .everyDay{
      top:50%;
     }

    .donation{
      text-align:center;
      background-color: rgba(245,245,245,0.7);
      width:100%;
      height:180px;
      opacity:0.85;
      position:absolute;
      top:12%;
      z-index:10;
      color:firebrick;
      font-weight: bold;
      padding-left:0;
      left: 0;
      margin-top:40px; 
  }

    .donation span{
      position:absolute;
      top:1%;
      font-size:3.5em;
      font-weight:800;
    }

    .btn{
      background-color: #0b3f76 !important;
      border: 0;
      height: 50px;
      width: 180px;
      font-weight: 600;
      font-size:1.5em;
      position:absolute;
      top:55%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    .emergency{
      padding:10px;
      position:absolute;
      z-index:10;
      background-color: firebrick;
      width:100%;
      /* padding-left:0; */
      top: 32.5%;
      left: 0;
      height:65px;
      /* margin-top:40px; */
    }
    
    .emergency span{
        display:inline-block;
        color:white;
        text-align:center;
        font-size:2.5em;
        font-weight:525;
        position:absolute;
        top:0;
        left:0;
    }

    .partner{
      display:flex;
      flex-direction: column;
      justify-content:flex-start;
      position:absolute;
      top:100%;
      background-color: rgba(245,245,245,0.7);
      opacity:0.9;
      width:100vw;
      margin-bottom:200px;
      height:370px;
      align-items: center;
    }

    .iconWrap{
      display: inline-block; 
      margin: auto;
      margin-top:25px;
      text-align:center;
  }
  
    .icon{
        border-radius:100px;
        margin-top:0;
        margin-bottom:0;
        background-color: #0b3f76;
        height: 4.5rem;
        width: 4.5rem;
        font-size: 3em;
        color: white;
        font-weight: bolder;
        text-align:center;
    }

    .partner span{
      font-size: 3em;
      font-weight: 700;
      position: absolute;
      top:30%;
    }

    .partner p{
      margin-top:40px;
      text-align: center;
      /* font-family: 'Noto serif'; */
      padding:25px;
      font-size:1.5em;
      font-weight:500;
      position:absolute;
      top:35%;
      max-width:800px;
    }

    .partner .btn{
      margin-top:2px;
      position:absolute;
      top:80%
    }
  }


  @media (min-width: 1600px) and  (max-width: 1920px){
    .top{
      padding:0px;
      margin-top:20px;
    }

    .savingLives, .everyDay{
      font-weight:790;
      font-size:4.5em;
      height:80px;
      position:absolute;
      position: absolute;
      text-align: center;
      width: 100%;
      left: 0;
      /* margin-bottom: 20px; */
    }

    .savingLives{
      top:10%
    }
    .everyDay{
      top:60%;
     }

    .donation{
      text-align:center;
      background-color: rgba(245,245,245,0.7);
      width:100%;
      height:220px;
      opacity:0.85;
      position:absolute;
      top:11%;
      z-index:10;
      color:firebrick;
      font-weight: bold;
      padding-left:0;
      left: 0;
      margin-top:40px; 
  }

    .donation span{
      position:absolute;
      top:1%;
      font-size:4.5em;
      font-weight:800;
    }

    .btn{
      background-color: #0b3f76 !important;
      border: 0;
      height: 60px;
      width: 220px;
      font-weight: 700;
      font-size:1.8em;
      position:absolute;
      top:55%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
      margin-top:10px;
    }

    .emergency{
      padding:10px;
      position:absolute;
      z-index:10;
      background-color: firebrick;
      width:100%;
      /* padding-left:0; */
      top: 25.5%;
      left: 0;
      height:75px;
      /* margin-top:40px; */
    }
    
    .emergency span{
        display:inline-block;
        color:white;
        text-align:center;
        font-size:3em;
        font-weight:525;
        position:absolute;
        top:0;
        left:0;
    }

    .partner{
      display:flex;
      flex-direction: column;
      justify-content:flex-start;
      position:absolute;
      top:100%;
      background-color: rgba(245,245,245,0.7);
      opacity:0.9;
      width:100vw;
      margin-bottom:200px;
      height:370px;
      align-items: center;
    }

    .iconWrap{
      display: inline-block; 
      margin: auto;
      margin-top:25px;
      text-align:center;
  }
  
    .icon{
        border-radius:100px;
        margin-top:0;
        margin-bottom:0;
        background-color: #0b3f76;
        height: 4.5rem;
        width: 4.5rem;
        font-size: 3em;
        color: white;
        font-weight: bolder;
        text-align:center;
    }

    .partner span{
      font-size: 3em;
      font-weight: 700;
      position: absolute;
      top:30%;
    }

    .partner p{
      margin-top:40px;
      text-align: center;
      /* font-family: 'Noto serif'; */
      padding:25px;
      font-size:1.5em;
      font-weight:500;
      position:absolute;
      top:35%;
      max-width:800px;
    }

    .partner .btn{
      margin-top:0px;
      position:absolute;
      top:78%
    }
  }
