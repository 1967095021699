.contactInfo {
    /* width:100%;
    padding:10px;
    margin:0; */
    /* width:400px !important; */
    /* width:400px; */
    flex-basis: 20%;
    margin:0;
    margin-top:10px;
}

.contactInfo span.caption{
    font-size: 1em;
    font-family: Open Sans;
    letter-spacing:0.05em;
    font-weight: 1000;
    color:white;
}

.contactInfo span.title{
    font-size: 0.7em;
    font-weight:600;
    /* font-family: Open Sans; */
    /* letter-spacing:0.05em; */
    /* color:rgb(152, 196, 50); */
    color:white;
}


.contactInfo span.text{
    font-size: 0.77em;
    /* font-family: Open Sans; */
    /* letter-spacing:0.05em; */
    /* color:rgb(152, 196, 50); */
    font-weight: 400;
    color:white;
}



.contactInfo .right{
    text-align:right;
}

.row{
    height: 25px;
}


@media (max-width: 414px) {
    .contactInfo .left{
        text-align:center;
    }
}

@media (min-width: 415px) and  (max-width: 767px){
    .contactInfo .left{
        text-align:center;
    }
}


@media (min-width: 768px) and  (max-width: 1023px){
    .caption{
        font-size: 1em;
    }
    .text{
        font-size:0.8em !important;
    }

    .caption, .text{
        padding-left:75px;
    }

    .img{
        /* width:100; */
    }

    .img-cell{
        width:100%;
    }
}

@media (min-width: 1024px) and  (max-width: 1920px){
    .contactInfo .left{
        text-align:left;
    }
}