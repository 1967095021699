html {
  height: 100%;
  font-size: 16px;
}

body, .wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

.header{
  background-color: darkred;
  height:200px;
  height:fit-content;
}

.content{
  /* height:1700px; */
 }

/* .footer {
  grid-row-start: 3;
  grid-row-end: 4;
  background: #42A5F5;
  color: white;
  padding: 20px;
} */


/* root{ */
  /* overflow:hidden; */
/* } */

/* body {
  margin: 0
} */

/* .app-wrap{
  min-height: 100%;
  display: grid;
  grid-gap:0;
  grid-template-rows: 1fr auto; */
  /* grid-template-rows: 190px 2fr 200px; */
  /* height:100vh; */
  /* max-height:100vh; */
  /* width:100vw;
  max-width:100vw; */
/* } */


/* .one{
/* background-color: red; */
/* }  */

/* .two{ */
  /* background-color: white; */
  /* overflow-y:auto; */
  /* margin-top:5px; */
/* } */

 /* .three{ */
/* background-color: blue;
grid-row-start: 3;
grid-row-end: 4; */
 /* }  */

/* .main{
  /* height:100%;
  max-height: 100%; */
  /* overflow-y: auto; */
/* } */ 

/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

.flex-row{
  display:flex;
  flex-direction: row;
}

.flex-col{
  display:flex;
  flex-direction: column;
}

.flex-center{
  justify-content: center;
}

.flex-right{
  justify-content: right;
}

.flex-left{
  justify-content: left;
}

.flex-wrap {
  flex-wrap: wrap;
}

.g20{
  gap: 20px;
}

.g35{
  gap: 35px
}

.g45{
  gap: 45px
}

.mr15{
  margin-right:15px;
}

::-webkit-scrollbar {
  width: 0px;
}

html{
  overflow-x: hidden;
}
