.footer {
    grid-row-start: 3;
    grid-row-end: 4;
    background: #42A5F5;
    color: white;
    padding: 10px;
    padding-bottom:2px;
    background:#0a3664; 
  }
  

.footer .outer{
    margin:10px;
    width:100%;
    justify-content: center;
    display: flex;
}

footer div a {
    color:goldenrod !important;
    font-weight: 700;
    font-size:1.2em;
    height: 80px;
}

footer .logo{
    height:80px;
    width:70px;
    margin:60px;
    margin-top:40px;
}

footer a{
    line-height: 100px;
    vertical-align: middle;
    height:100%;
}

footer .ad{
    /* float:right; */
    color:goldenrod;
    margin-bottom:12px;
    /* margin-right:10px; */
    font-size:1em;
}

.logo-cell{
    justify-content: right;
    text-align:right;
    /* border-right: 2px solid whitesmoke !important; */
}

@media (max-width: 414px) {
    .footer .outer{
        flex-direction: column;
        align-content: space-evenly;
        row-gap: 40px;
        justify-content: center;
        width:95%;
    }

    .ad{
        margin-top:20px;
        /* padding-left:30px; */
        width:100%;
        text-align:center;
    }

    .contactInfo{
        width: 100%;
    }

    .contactInfo .left{
        text-align:center;
    }

    .outer .img{
        display: flex;
        justify-content: center;
    }

    footer .logo{
        height:80px;
        width:70px;
        margin:10px;
    }
}

@media (min-width:415px) and (max-width: 600px) {
    .footer .outer{
        flex-direction: column;
        align-content: space-evenly;
        row-gap: 40px;
        justify-content: center;
        width:95%;
    }

    .ad{
        margin-top:20px;
        /* padding-left:30px; */
        width:100%;
        text-align:center;
    }

    .contactInfo{
        width: 100%;
    }

    .contactInfo .left{
        text-align:center;
    }

    .outer .img{
        display: flex;
        justify-content: center;
    }

    footer .logo{
        height:80px;
        width:70px;
        margin:10px;
    }
}

@media (min-width:601px) and (max-width: 767px) {
    .footer .outer{
        flex-direction: column;
        align-content: space-evenly;
        row-gap: 40px;
        padding-left:250px;
    }

    .ad{
        /* margin-top:10px;
        padding-left:200px; */
        width:100%;
        text-align: center;
    }
}

@media (min-width: 768px) and  (max-width: 1023px){
    .footer .outer{
        flex-direction: row;
        align-content: space-evenly;
        row-gap: 100px;
        justify-content: center;
        width:98%;
        flex-wrap:wrap;
    }

    .tbl{
        flex-basis:360px;
        text-justify: center;
    }

    .ad{
        margin-top:20px;
        font-size: 1em;
        width:100%;
        text-align:center;
    }

    .contactInfo{
        width: 100%;
    }

    .contactInfo .left{
        text-align:left;
    }

    footer .logo{
        height:70px;
        width:60px;
        margin:5px;
    }

    .img-cell{
        width: 100%;
        height:120px;
        display:flex;
        justify-content: center;
        align-items:center;
    }

    .img{
        width:100px;
        text-align: center;
    }

    .caption{
        font-size:1.em;
    }

}

@media (min-width: 1024px) and  (max-width: 1920px){
    .footer .outer{
        flex-direction: row;
        align-content: space-evenly;
        row-gap: 40px;
        justify-content: center;
        width:98%;
        /* margin-left:100px; */
    }

    .ad{
        margin-top:20px;
        /* padding-left:30px; */
        width:100%;
        text-align:right;
    }

    .contactInfo{
        width: 100%;
    }

    .contactInfo .left{
        text-align:left;
    }

    footer .logo{
        height:80px;
        width:70px;
        margin:20px;
    }

    .img{
        width:160px;
        text-align: center;
    }
}