.donate{
    height:100vh;
    max-height:100%;
    width:100%;
    max-width:100%;
    /* float:left; */
    margin-top:20px;
    margin-bottom:20px;
    /* margin-left:50px; */
}

.iframe{
    height:100%;
    width:100%;
}

@media (max-width: 414px) {
    .donate{
        height:1750px;
    }

}

@media (min-width: 1024px) and  (max-width: 1920px){
    .donate{
        height:1400px;
    }

}
