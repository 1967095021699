.banner {
    padding-top: 0;
    background-color:firebrick !important;
}

@media (max-width: 320px) {
    .banner{
        position: relative;
        height:70px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -20%;
        left:4%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 1.85em !important;
        width: 60%;
        margin-left: 0;
        position: absolute;
        left: 39%;
        font-weight: 600;
    }

    .brand-caption .of{
        font-weight: 300;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 5px;
        width:20%;
    }

    .logo  {
        height:110px;
        width:90px;
        /* margin-left: 15px; */
        /* left:15%; */
        margin-top:0;
    }
  } 

@media (min-width: 321px) and (max-width: 414px) {
    .banner{
        position: relative;
        height:78px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -20%;
        left:4%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 2em !important;
        width: 60%;
        margin-left: 0;
        position: absolute;
        left: 34%;
        font-weight: 600;
    }

    .brand-caption .of{
        font-weight: 300;
    }

    .navbar-brand {
        left: 90px;
        margin-top: 5px;
        width:20%;
    }

    .logo  {
        height:120px;
        width:100px;
        /* margin-left: 15px; */
        /* left:15%; */
        margin-top:0;
    }
  }

  @media (min-width: 415px) and  (max-width: 767px) {
    .banner{
        position: relative;
        height:35px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -40%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 2.4em !important;
        width: 100%;
        margin-left: 0;
        position: absolute;
        left: 13%;
        font-weight: 750;
        line-height: .95;
        height:70px;
    }

    .brand-caption .of{
        font-weight: 50;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 0px;
        width:20%;
    }

    .logo {
        height:60px;
        width:50px;
        margin-left: 10px;
        margin-top:5px;
    }
  }

  @media (min-width: 768px) and  (max-width: 1023px){
    .banner{
        position: relative;
        height:51px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -40%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 3.5em !important;
        width: 100%;
        margin-left: 0;
        position: absolute;
        left: 14%;
        font-weight: 750;
        line-height: .95;
        height:70px;
    }

    .brand-caption .of{
        font-weight: 50;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 0px;
        width:20%;
    }

    .logo  {
        height:90px;
        width:70px;
        margin-left: 20px;
        margin-top:5px;
    }
  }


  @media (min-width: 1024px) and  (max-width: 1279px){
    .banner{
        position: relative;
        height:62px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -40%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 4.2em !important;
        width: 100%;
        margin-left: 0;
        position: absolute;
        left: 12.5%;
        font-weight: 750;
        line-height: .95;
        height:70px;
    }

    .brand-caption .of{
        font-weight: 50;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 0px;
        width:20%;
    }

    .logo  {
        height:120px;
        width:100px;
        margin-left: 15px;
        margin-top:5px;
    }
  }
/* 
  @media (min-width: 1024px) and  (max-width: 1279px){

  } */

  @media (min-width: 1280px) and  (max-width: 1365px){
    .banner{
        position: relative;
        height:62px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -40%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 4.2em !important;
        width: 100%;
        margin-left: 0;
        position: absolute;
        left: 10%;
        font-weight: 750;
        line-height: .95;
        height:70px;
    }

    .brand-caption .of{
        font-weight: 50;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 0px;
        width:20%;
    }

    .logo  {
        height:120px;
        width:100px;
        margin-left: 20px;
        margin-top:5px;
        left:10%;
    }
  }

  @media (min-width: 1366px) and  (max-width: 1535px){
    .banner{
        position: relative;
        height:62px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -40%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 4.2em !important;
        width: 100%;
        margin-left: 0;
        position: absolute;
        left: 9.5%;
        font-weight: 750;
        line-height: .95;
        height:70px;
    }

    .brand-caption .of{
        font-weight: 50;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 0px;
        width:20%;
    }

    .logo  {
        height:120px;
        width:100px;
        margin-left: 15px;
        margin-top:5px;
        left:10%;
    }
  }

  @media (min-width: 1536px) and  (max-width: 1599px){
    .banner{
        position: relative;
        height:62px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -40%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 4.2em !important;
        width: 100%;
        margin-left: 0;
        position: absolute;
        left: 9%;
        font-weight: 750;
        line-height: .95;
        height:70px;
    }

    .brand-caption .of{
        font-weight: 50;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 0px;
        width:20%;
    }

    .logo  {
        height:120px;
        width:100px;
        margin-left: 15px;
        margin-top:5px;
        left:10%;
    }
  }
  
 @media (min-width: 1600px) and  (max-width: 1919px){
    .banner{
        position: relative;
        height:80px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -40%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 5.4em !important;
        width: 100%;
        margin-left: 0;
        position: absolute;
        left: 10.5%;
        font-weight: 750;
        line-height: .95;
        height:70px;
    }

    .brand-caption .of{
        font-weight: 50;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 0px;
        width:20%;
    }

    .logo  {
        height:160px;
        width:140px;
        margin-left: 15px;
        margin-top:5px;
    }
}

@media (min-width: 1920px) and (max-width: 2559px){
    .banner{
        position: relative;
        height:80px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -40%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 5.4em !important;
        width: 100%;
        margin-left: 0;
        position: absolute;
        left: 10.5%;
        font-weight: 750;
        line-height: .95;
        height:70px;
    }

    .brand-caption .of{
        font-weight: 50;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 0px;
        width:20%;
    }

    .logo  {
        height:160px;
        width:140px;
        margin-left: 33px;
        margin-top:5px;
    }
}

@media (min-width: 2560px) {
    .banner{
        position: relative;
        height:80px !important;
        padding-top: 0;
        padding-bottom: 0px;
        justify-content: flex-start !important;
        width:100%;
    }

    .banner a{
        position: relative;
        top: -40%;
    }

    .brand-caption {
        color:whitesmoke !important;
        font-size: 5.4em !important;
        width: 100%;
        margin-left: 0;
        position: absolute;
        left: 10.5%;
        font-weight: 750;
        line-height: .95;
        height:70px;
    }

    .brand-caption .of{
        font-weight: 50;
    }

    .navbar-brand{
        left: 90px;
        margin-top: 0px;
        width:20%;
    }

    .logo  {
        height:160px;
        width:140px;
        margin-left: 33px;
        margin-top:5px;
    }
}