.contact{
    padding-bottom:40px;
}

.contact .caption{
    color:firebrick;
    font-size:2em;
    font-weight: 700;
    margin-top:50px;
    text-align:right;
}

.contact p{
    text-align:right;
    font-family: Noto Serif;
    line-height:1.9;
    font-size: 1.2em;
}

.contact label{
    color: #0a3664 !important;
    font-weight:600 !important;
}

button.email{
    color: #0a3664 !important;
    font-weight:600;
    background-color:transparent;
    text-decoration: none;
    border:none;
    margin-right:0;
    padding:0;
}

button.email:hover{
    text-decoration: underline;
}

button.email:focus{
    text-decoration: underline;
}

@media (max-width: 414px) {
    .contact{
        padding-left:22px;
    }
    .contact .caption{
        text-align:center;
    }

    .contact p{
        text-align:center;
    }
 }

 @media (min-width:601px) and (max-width: 1280px) {
    .contact{
        padding-left:22px;
    }
    .contact .caption{
        text-align:center;
    }

    .contact p{
        text-align:center;
    }
 }

 @media (min-width: 1024px) and  (max-width: 1920px){
    .contact{
        /* padding-left:22px; */
    }
    .contact .caption{
        text-align:right;
    }

    .contact p{
        text-align:right;
    }

    .contactStory{
        padding-right:40px;
    }

    .contact .caption{
        font-size:2.2em;
    }
 }