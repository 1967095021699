.contactForm{
    margin-top:60px;
    background-color: #f4f2f2;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.submitButton{
    background-color: #0a3664 !important;
    border:0 !important;
    font-weight:600;
}

@media (max-width: 414px) {
    .contactForm{
        margin-top:10px;
    }

    .submitButtonWrapper{
        text-align: center;
    }


 }