.sub-caption-wrapper{
  border-bottom: 1px   solid #0b3f76;
}

.sub-caption{
    line-height:1.6;
    color:#0b3f76; 
    font-weight:600; 
}

@media (max-width: 320px) {
  .sub-caption{
    margin-top:27px;
    margin-bottom:4px;
    margin-left:20px;
    margin-right:5px;
    font-size: 0.7em !important;
    height:35px;
  }
}

@media (min-width: 321px) and (max-width: 414px) {
   .sub-caption{
     margin-top:27px;
     margin-bottom:4px;
     margin-left:20px;
     margin-right:5px;
     font-size: 0.85em !important;
     height:50px;
   }
}

@media (min-width: 415px) and  (max-width: 767px){
  .sub-caption-wrapper{
    height:50%;
  }

  .sub-caption{
    margin-top:15px;
    margin-bottom:3.8px;
    margin-left:25px;
    margin-right:5px;
    font-size: 1em !important;
    height:50px;
  }
}


@media (min-width: 768px) and  (max-width: 1023px){
  .sub-caption-wrapper{
    height:50%;
  }

  .sub-caption{
    margin-top:22px;
    margin-bottom:3.8px;
    margin-left:25px;
    margin-right:5px;
    font-size: 1.3em !important;
    height:70px;
  }
}

@media (min-width: 1024px) and  (max-width: 1435px){
  .sub-caption-wrapper{
      height:50%;
  }

  .sub-caption{
    margin-top:10px;
    margin-bottom:3.8px;
    margin-left:130px;
    margin-right:5px;
    font-size: 1.2em !important;
    height:35px;
  }

}

@media (min-width: 1436px) and  (max-width: 1439px){
  .sub-caption-wrapper{
      height:50%;
  }

  .sub-caption{
    margin-top:10px;
    margin-bottom:3.8px;
    margin-left:138px;
    margin-right:5px;
    font-size: 1.2em !important;
    height:35px;
  }

}

@media (min-width: 1440px) and  (max-width: 1599px){
  .sub-caption-wrapper{
      height:50%;
  }

  .sub-caption{
    margin-top:10px;
    margin-bottom:3.8px;
    margin-left:138px;
    margin-right:5px;
    font-size: 1.2em !important;
    height:35px;
  }

}

@media (min-width: 1600px) and  (max-width: 1920px){
  .sub-caption-wrapper{
      height:50%;
  }

  .sub-caption{
    margin-top:10px;
    margin-bottom:3.8px;
    margin-left:170px;
    margin-right:5px;
    font-size: 1.2em !important;
    height:35px;
  }

}

@media (min-width: 1920px){
  .sub-caption-wrapper{
      height:50%;
  }

  .sub-caption{
    margin-top:10px;
    margin-bottom:3.8px;
    margin-left:205px;
    margin-right:5px;
    font-size: 1.4em !important;
    height:35px;
  }

}