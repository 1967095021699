.background{
    z-index:1;
    position:relative;
    padding:0;
}

.row{
    --bs-gutter-x: 0 !important;
}

@media (max-width: 414px) {
    .background{
        margin-bottom: 370px;
    }
}

@media (min-width: 415px) and  (max-width: 767px){
    .background{
        margin-bottom: 370px;
    }
}

@media (min-width: 768px) and  (max-width: 1023px){
    .background{
        margin-bottom: 370px;
    }
}

@media (min-width: 1024px) and  (max-width: 1920px){
    .background{
        margin-bottom: 370px;
    }
}