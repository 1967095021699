.modalHeader{
    background-color:#f4f2f2 !important;
}

.yesBtn{
    background-color:#0a3664;
    border-color: #0a3664;
}

.confirm{
    
}