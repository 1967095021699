.story{
    /* margin:400px; */
    /* margin-left:193px; */
    margin-top:40px;
    margin-bottom:40px;
    width:80%;
}

.story h3 {
    color:firebrick;
    font-weight:750;
}

.story p {
    font-family: Noto Serif;
    font-size:1.2em;
    line-height: 1.7;
} */

/* .col-md-6{
    padding-right:initial;
    padding-left:40px;
    
}

.col-md-6:first-of-type{
    padding-right:25px;
    padding-left:initial;
} */

/* .meet-us img{
    width:100%;
} */



/* .history h3, .history p{
    text-align: right;
} */


.mission h3, .mission p{
    text-align: left;
}

@media (max-width: 414px) {
    .story{
        margin-top:5px;
        margin-bottom:40px;
        width:80%;
    }

    .story h3, .story p{
        text-align: center;
    }

    .story h3 {
        margin-bottom:20px;
        font-size: 2em;
    }
    
    .story p {
        font-family: Noto Serif;
        font-size:1.1em;
        line-height: 1.7;
    }

    .member-on-call{
        display:flex;
        justify-content:center;
        width:100%;
    }

    .member-on-call img{
        width: 80%;
        resize: cover;
    }

    .meet-us-row{
        display: flex;
        flex-direction: column-reverse;
    }

    .meet-us{
        display:flex;
        justify-items: center;
        justify-content: center;
    }

    .meet-us img {
        width:110%;
        margin-top:40px;
        display: flex;
        justify-content: center;
        resize: cover;
    }

}

@media (min-width: 1024px) and  (max-width: 1920px){
    .story{
        margin-top:5px;
        margin-bottom:40px;
        width:100%;
    }

    .story h3, .story p{
        text-align: right;
    }

    .mission{
        padding-left:60px;
    }

    .mission h3, .mission  p{
        text-align: left;
    }

    .story h3 {
        margin-bottom:20px;
        font-size: 2em;
    }
    
    .story p {
        font-family: Noto Serif;
        font-size:1.3em;
        line-height: 1.8;
    }

    .member-on-call{
        display:flex;
        justify-content:right;
        width: 45%;
    }

    .member-on-call img{
        width: 90%;
        resize: cover;
    }

    .meet-us-row{
        display: flex;
        /* flex-direction: column-reverse; */
    }

    .meet-us{
        display:flex;
        justify-items: center;
        justify-content: center;
    }

    .meet-us img {
        width:110%;
        margin-top:0px;
        display: flex;
        justify-content: center;
        resize: cover;
    }

}
