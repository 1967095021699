.about h1{
    margin-top:40px;
    color:firebrick;
    font-weight:800;
    font-size:4.5em;
    text-align:center;
}

.meet-us{
    text-align: center;
}

@media (max-width: 414px) {
    .about h1{
        font-size:3.3em;
        margin-bottom:20px;
    }
}