/* h1.brand-caption{
  color:whitesmoke !important;
  margin-bottom:0;
  font-weight: 750;
} */

/* nest hub max and below */
/* @media (max-width: 1124px) { */
  /* .brand-caption {
    font-size: 4.5em !important;
    margin-left:165px;
  }

  nav.banner{
    height:90px;
  }

  nav.banner a {
    margin-left:120px;
    margin-top:25px;
  }

  nav.navbar .navbar-brand img {
    height:150px;
    width:120px;
    margin-left: 20px;
  } */
 /* } */

 /* nest hub max and below */
 /* @media (min-width: 1125px) {
  .brand-caption {
    font-size: 5.5em !important;
    margin-left:165px;
  }

  nav.banner{
    height:90px;
  }

  nav.banner a {
    margin-left:130px;
    margin-top:20px;
  }

  nav.navbar .navbar-brand img {
    height:170px;
    width:140px;
    margin-left: 40px;
  }
 } */

/* @media only screen and (min-device-width: 992px) { */
  /* h4{ */
    /* font-size: 1.5em; */
    /* color:#0b3f76; */
    /* margin-top:5px; */
    /* font-weight:600; */
    /* padding-left: 168px; */
    /* height:28px; */
    /* background-color: white; */
    /* margin-bottom:10px; */
    /* height:fit-content;
    max-height:fit-content; */
  /* } */
/* } */

 /* @media only screen and (max-device-width: 991px) { */
  /* h4{ */
    /* font-size: 1.5em; */
    /* color:#0b3f76; */
    /* margin-top:5px; */
    /* font-weight:600;
    padding-left: 168px;
    height:100px;
    background-color: white;
    padding-right:15px; */
    /* margin-bottom:10px; */
    /* height:fit-content;
    max-height:fit-content; */
  /* } */
 /* } */

  /* @media only screen and (max-device-width: 720px) { */
    /* h4{ */
      /* font-size: 1.9em; */
      /* color:#0b3f76; */
      /* margin-top:5px; */
      /* font-weight:600; */
      /* padding-left: 168px; */
      /* height:120px; */
      /* background-color: white; */
      /* padding-right:15px; */
      /* margin-bottom:10px; */
      /* height:fit-content;
      max-height:fit-content; */
    /* } */
 /* } */

 /* @media only screen and (max-device-width: 360px) { */
  /* h4{ */
    /* font-size: 2em; */
    /* color:#0b3f76; */
    /* margin-top:5px; */
    /* font-weight:600; */
    /* padding-left: 168px; */
    /* height:130px; */
    /* background-color: white; */
    /* padding-right:15px; */
    /* margin-bottom:10px; */
    /* height:fit-content;
    max-height:fit-content; */
  /* } */
/* } */

 /* @media only screen and (max-device-width: 1024px) {
  .brand-caption {
    font-size: 4.3em !important;
    margin-left:165px;
  }

  nav.banner{
    height:90px;
  }

  nav.banner a {
    margin-left:120px;
  }

  nav.navbar .navbar-brand img {
    height:150px;
    width:120px;
    margin-left: 20px;
  }
 } */
/* 
 nav.banner .navbar-brand {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -50px;
  transform: translateX(-50%);
  padding: 0px;
} */

 /* @media only screen and (max-device-width: 414px) {
  .brand-caption {
    font-size: 4.3em !important;
    margin-left:165px;
  }

  nav.banner{
    height:90px;
  }

  nav.banner a {
    margin-left:120px;
  }

  nav.navbar .navbar-brand img {
    height:150px;
    width:120px;
    margin-left: 20px;
  }
 } */



/* .navbar-toggler
{
    position: absolute;
    right: 11px;
} */

header{
  /* position:fixed; */
  /* width:100%; */
}

nav.menu{
  /* height:30%; */
}

nav.menu a{
  /* margin-top:5px !important;
  padding-top:5px !important; */
}

/* nav.menu .navbar-collapse {
  border: 0;
} */




  /* nav.navbar .navbar-brand img {
    height:190px;
    width:160px;
    margin-left: -50px;
  } */
  /* @media only screen and (min-width: 768px) { */
    /* nav.menu { */
      /* margin-top: 20px; */
      /* margin-bottom: 0px; */
      /* min-height: 60px;
      height: 120px; */
      /* box-shadow: 0px 5px 5px rgb(228, 227, 227), 0px -5px 5px rgb(228, 227, 227); */
    /* } */
    /* nav.navbar .navbar-nav > li > a { */
      /* font-size: 12px; */
      /* padding: 0px 10px; */
      /* padding-top:5px; */
    /* } */

    /* nav.navbar .navbar-nav a {
      font-size: 12px;
      padding: 0px 10px;
      padding-top:5px;
    } */


    /* nav.navbar .navbar-brand {
      top: -65px;
    } */
  /* } */
  /* @media only screen and (max-width: 767px) {
    nav.navbar .navbar-nav {
      margin-top: 50px;
    }
  } */


  /* @media only screen and (min-width: 1228px) {
    h1.brand-caption {
      font-size:6em;
    }
  } */

   /* @media only screen and (max-width: 1227px) {
    h1.brand-caption {
      font-size: 5.8em;
     }
   } */

   /* @media only screen and (max-width: 375) {
    h1.brand-caption {
      font-size: 4.3em;
     }

     nav.banner{
       height:90px;
     }
   } */


   /* @media only screen and (min-width: 1124px) {
    h4 { */
      /* height:28px; */
     /* }
   } */

   /* @media only screen and (max-width: 1123px) {
    h4 { */
      /* height:65px; */
     /* }
   } */

/* .navbar-nav{
  gap:75px !important;
  margin-right:18px;
  z-index:-1;
} */

.navbar{
  margin:0 !important;
}

.navbar-toggler-right:not(collapsed){
  margin-right:10px;
}

.navbar-nav{
  margin:0;
}

.navbar-nav a{
    text-decoration:none;
    font-weight:500;
    font-size: 1.4em;
    color:#0b3f76 !important;
    margin:20px;
    margin-top:25px;
}

.navbar-nav a.active{
    color:firebrick !important;
    font-weight:bold  !important; 
}

/* .navbar-nav a:hover{
    font-weight:bold; 
} */

.donate-menu-btn{
  background-color: #0a3664;
  color:white;
  font-weight:900;
  height:40px;
  padding-top:0;
  width:100px;
  margin-left:10px;
  font-size:1.2em;
}
.diamond{
  font-size:0.8em;
  color:yellowgreen;
  text-shadow: 0px 1.4px #0a3664;
  margin:2px
}

 /* Moto G4 & below*/
 @media (max-width: 574px) {
  .navbar-collapse{
    margin-bottom: 15px;
}

 }

 @media (min-width: 1024px) and  (max-width: 1920px){
   .navbar{
     height:65px !important;
   }
  .navbar-collapse{
    margin-bottom: 0px;
    margin-left:0px !important;
    padding-right: 0;
  }

 }

 .navbar-collapse{
  justify-content:flex-end;
 }